import React from 'react';
import { WindowMode } from '../App';
import ResumeText from '../components/resume-text';
import ViewHeader from '../components/view_header';
import ViewSection from '../components/view_section';

export interface AboutMeProps {
  viewMode: WindowMode;
  handleSelectDestination: (dest: string) => void;
}

export default class AboutMeView extends React.Component<AboutMeProps> {
  public render() {
    const today = new Date();
    const birthday = new Date('10/15/1993');
    let age = today.getFullYear() - birthday.getFullYear();
    const month = today.getMonth() - birthday.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }

    if (
      this.props.viewMode === 'mobile' ||
      this.props.viewMode === 'tablet-vertical'
    ) {
      return <div className="view-wrapper">Mobile View Coming Soon</div>;
    } else {
      return (
        <div className="view-wrapper view-background">
          <div className="about-description">
            <ViewHeader>Hi, I'm Ramell</ViewHeader>
            <ViewSection>
              <ResumeText viewMode={this.props.viewMode} title="Who Am I?">
                <div>
                  I am a Grand Rapids, Michigan Native. I always thought I would
                  leave, but the natural charm of my city, the healthy economy
                  and the opportunities I have made for myself here have made it
                  hard. I am {age} years old and I have done way more than I
                  ever expected I would in life. I earned a degree in culinary
                  arts, I earned a degree in computer science, I have had one
                  successful career and have forged a successful path into
                  another. I have traveled all over the country, attended music
                  festivals, gone on cruises, found the love of my life, bought
                  a house, found wonderful friends that I consider family and
                  have even invented a new food product. Whew let me stop to
                  catch my breath after that run on sentence. But overall I am
                  happy. Something I never forget to appreciate.
                </div>
                <div className="view-spacer">
                  My entire life I have (borderline recklessly) chased after
                  what I wanted. Whether that be an inflatable hot tub that I
                  was definitely not allowed to have at my rental, a new skill
                  or a new career position. I have always pushed myself to go a
                  little bit further, to have a little more faith in myself even
                  when I fail, to learn one more thing and try one more thing. I
                  believe that this has benefitted me in my careers, in my
                  personal life and even in my hobbies and{' '}
                  <a
                    className="bio-links"
                    onClick={() =>
                      this.props.handleSelectDestination('projects')
                    }
                  >
                    Projects
                  </a>
                  . Even if I didn't quite end up where I wanted to be 15 years
                  ago. I did end up with an extremely unique set of skills that
                  I pride myself on and that allow me to passionately chase
                  after things I am interested in.
                </div>
              </ResumeText>
            </ViewSection>
            <ViewSection>
              <ResumeText viewMode={this.props.viewMode} title="Why Food?">
                <div>
                  Growing up I wanted to be a video game developer. Today I
                  still hold that passion and spend tons of time appreciating
                  video games. From the art, the stories, the communities, the
                  immersion. Also, why doesn't anyone ever talk about how
                  impressive video game physics are? Or how smart the people
                  developing the net code are? I began learning software
                  development when I was in high school and I could barely get a
                  java GUI to update, I couldn't imagine making multiple people
                  on different devices move in low latency and shoot at each
                  other while the game world responds to them.
                </div>
                <div className="view-spacer">
                  When I graduated high school, I began trying to figure out how
                  to make it happen. I wanted to attend a video game school in
                  Chicago. It's been so long I don't even remember the name and
                  I can't find it on google, so it's probably a good thing I
                  didn't go. I went and toured it and I had my heart set on it.
                  Until I heard the price and had my dreams crushed. I decided
                  that I would do whatever I could to make it happen and I
                  decided to go to community college at home to find a skill I
                  could use to support myself.
                </div>
                <div className="view-spacer">
                  It sounds crazy, but I was 17 and because I couldn't afford
                  going to a "real" college I decided to do what my dad did at
                  the time. He got me a job working at a college in the kitchen
                  and I started going to culinary school to provide myself with
                  better skills to get better jobs and move to chicago. In hopes
                  of working in restaurants at night and go to dream school
                  during the day.
                </div>
                <div className="view-spacer">
                  Little did I know that would change my life completely. Have
                  you ever tasted 100 year old balsamic? Ever met a masterchef?
                  Spent 6 months working on something and when you finish it you
                  are emotionally fulfilled by that product? Every day as a
                  young chef is new inspiration. The deeper and deeper I went
                  the more there was to find. I graduated in 2013 and to this
                  day I still learn new things about food in my research, in my
                  work and from other chefs when I visit their restaurants. It
                  went from being a short means of getting a different job to
                  the center of my life, my everything, my identity. But I guess
                  working 10-12 hours a day, 5-6 days a week will do that to you
                  too. After all these years I pride myself on being a chef, I
                  worked extremely hard to get to where I was and people respect
                  me for my work and my passion. Its a job with an extremely
                  high skill ceiling and one of the few jobs in the world where
                  you can be part of the whole process from start to finish.
                </div>
              </ResumeText>
            </ViewSection>
            <ViewSection>
              <ResumeText viewMode={this.props.viewMode} title="Why Computers?">
                <div>
                  As I said in my 'Why food?' section, I began learning software
                  development in high school. I spent most of my childhood with
                  computers and devices, consoles, etc. I always tinkered with
                  my devices and I won't lie about it, it's not because I was
                  curious of how it worked. My family wasn't super well off, so
                  I would do whatever I could to pirate games and add features
                  to devices that typically you had to pay extra for and you
                  know jailbreaking was cool. I was always kind of a little nerd
                  and a bit of a rebel for really no reason, constantly doing
                  things that I could get in real trouble for. Out late skating
                  until 3 AM. I'm scared to have kids by the way, probably not
                  associated. When I was in high school I became the junior tech
                  guy. I would help run assemblies and dances. I had the liberty
                  to leave class whenever I needed to for events. Oh boy did I
                  abuse that. From using the multimedia room projector to play
                  mario kart with friends during class to setting up routers all
                  over the school connected to lan ports that had separate
                  passwords so we could bring our own devices, which wasn't
                  allowed at the time for so many reasons. My entire life, I was
                  always involved with tech in one way or another. It's just
                  unfortunate that it didn't become cool to be a techy bad boy
                  until after I was an adult
                </div>
                <div className="view-spacer">
                  After graduating from culinary school, I was laser focused on
                  working in restaurants and trying to find opportunities to
                  grow. My grandmother who practically raised me, sat me down
                  one day and told me I needed to slow down and think about my
                  future, restaurant work is hard on you, it's not a forever
                  solution. She was right, none of my plans were concrete, I
                  wasn't thinking further than a few feet out. She reccomended I
                  think about computers because I always had a knack for it. By
                  this time I had learned python 2 and java and a little bit
                  about the linux system. But I hadn't used any of it in a few
                  years and my coding was really messy. So i knew I couldn't
                  just go out and get a job in the field.
                </div>
                <div className="view-spacer">
                  Since Grand Valley State University was close to home and I
                  could still work and support myself in quality kitchens, I
                  decided to give it a shot and enrolled in the Computer Science
                  department. There were a lot of ups and downs. I went back and
                  forth on what I would actually do with my degree, the video
                  game dream was slowly dying as there wasn't much course
                  material built around it. In the end I discovered a new
                  passion or really an old one, tinkering, like I did as a
                  teenager. So I picked up a second major in Computer
                  Engineering. After 7 horribly stressful years, I ran out of
                  mental bandwith and money and I finally ended up graduating
                  with a Computer Science Major and a Computer Engineering
                  Minor. I have ideas of what I want to do for work but I am
                  also trying out new things as often as possible and working on
                  my own projects in hopes of finding a new career move to be
                  passionate about.
                </div>
              </ResumeText>
            </ViewSection>
            <ViewSection>
              <ResumeText viewMode={this.props.viewMode} title="Whats Next?">
                <div>
                  As of today, I plan to continue working as a Software engineer
                  in some capacity, whether that be full stack web development,
                  embedded systems development, game development, app
                  development or a combination of the above. At least until I am
                  financially secure enough to go out on my own and open my own
                  commpany.
                </div>
                <div className="view-spacer">
                  My partner and I have been working on the{' '}
                  <a
                    className="bio-links"
                    target="_blank"
                    href="https://www.instagram.com/___spyre___/"
                  >
                    Spyre Dinner Project
                  </a>{' '}
                  for a few years now and hope to grow it into a small
                  restaurant where we can work on a lot of problems that the
                  restaurant industry is bogged down by. We have the goal of
                  empowering more chefs to own and operate their own projects by
                  providing the blueprint and encourage a culture of modernized
                  farm to table service. I believe with the right tech, the
                  right product management systems and the correct mentality on
                  service, chefs can thrive without an owner above them
                  demanding profit driven changes. Every business needs to make
                  money of course, but it should never come at the expense of
                  quality or the health/safety/passion of those keeping it
                  running. We also hope to add momentum to the return to local
                  push we have been seeing around the country recently. Most
                  people don't use all local food, because it's hard to source,
                  its more expensive, its not always available. I think with the
                  partnership of other passionate food tech companies around the
                  country implementing new farming techniques and new
                  preservation techniques without chemical additives we can
                  dramatically change the food landscapes we see and do more to
                  support our local farmers.
                </div>
                <div className="view-spacer">
                  This project doesn't really relate directly to any job I can
                  have in the computer field. However, it will utilize all of
                  the skills, app development for easy to use interfaces and
                  quick reporting, embedded design and product development for
                  who knows what system or robotic implentation I will think of,
                  full stack development for in house management systems and IOT
                  driven devices and processes for security, automatic
                  restaurant management and honestly just making stuff really
                  cool. I plan to make our restaurant an example of success to
                  develop products and change the way restaurants operate. It's
                  a huge dream, but it's the first dream I have ever had that
                  makes me feel like I can actually make a difference in the
                  world
                </div>
                <div className="view-spacer">
                  I am a very passionate professional and I don't do things or
                  work in places I don't feel are good for me or I am not good
                  for. I pride myself on being honest to myself and honest to
                  those around me, so If you see me applying for a position or
                  trying something new. Know its because I am truly interested
                  and truly excited to be a part of it.
                </div>
              </ResumeText>
            </ViewSection>
          </div>
        </div>
      );
    }
  }
}
