import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

export default class LinkedInContact extends React.Component {
    public render() {
        return (
            <a className="link" href="https://www.linkedin.com/in/ramell-collins/">
                <FontAwesomeIcon icon={faLinkedin} className="icon" size="2x"/>
            </a>
        )
    }
}