import React from 'react';
import ViewHeader from '../components/view_header';
import Slides from '../components/horizontal-slides';
import { WindowMode } from '../App';

//scss
import './tab_view.scss';

export interface BioViewProps {
  viewMode: WindowMode;
  handleSelectDestination: (dest: string) => void;
}

export default class BioView extends React.Component<BioViewProps> {
  state = { showContactMe: false };

  public render() {
    const Zoom = require('react-reveal/Zoom');

    const slides = [
      {
        title: 'Chef',
        body: (
          <>
            <div className="bio-points">
              Secchia Institute of Culinary Arts Graduate 2013
            </div>
            <div className="bio-sub-details">
              Associates of Arts - Culinary Arts
            </div>
            <div className="bio-points">
              Super Passionate Culinarian with 9 years of{' '}
              <a
                className="bio-links"
                onClick={() => this.props.handleSelectDestination('fResume')}
              >
                Experience
              </a>
            </div>
            <div className="bio-points">
              Currently Developing the{' '}
              <a
                className="bio-links"
                target="_blank"
                href="https://www.instagram.com/___spyre___/"
              >
                Spyre Dinner Project
              </a>
            </div>
          </>
        ),
        key: 0,
      },
      {
        title: 'Software Engineer',
        body: (
          <>
            <div className="bio-points">
              Grand Valley State University Graduate 2020
            </div>
            <div className="bio-sub-details">
              Bachelors of Science - Computer Science
            </div>
            <div className="bio-sub-details">Minor - Computer Engineering</div>
            <div className="bio-points">
              3 Years Full Stack Development{' '}
              <a
                className="bio-links"
                onClick={() => this.props.handleSelectDestination('cResume')}
              >
                Experience
              </a>
            </div>
          </>
        ),
        key: 1,
      },
      {
        title: 'Food Science Enthusiast',
        body: (
          <>
            <div className="bio-points">
              Big Dipper Cookie Dough Food Science Consultant - 2018
            </div>
            <div className="bio-points">
              Research in all Forms of Fermentation and Preservation
            </div>
            <div className="bio-points">
              Working on{' '}
              <a
                className="bio-links"
                onClick={() => this.props.handleSelectDestination('projects')}
              >
                Projects
              </a>{' '}
              for Sustainability and Accessibility
            </div>
          </>
        ),
        key: 2,
      },
    ];
    return (
      <div className="view-wrapper view-background">
        <div className="bio-description">
          <div className="bio-header">
            <div className="bio-welcome">
              <Zoom>
                <ViewHeader>Ramell Collins</ViewHeader>
              </Zoom>
              <Zoom>
                <div className="bio-description__body">
                  <Slides speed={7500} slides={slides} slidesPerView={1} />
                </div>
              </Zoom>
            </div>
          </div>
        </div>
        <Zoom>
          <div className="bio-image"></div>
        </Zoom>
      </div>
    );
  }
}
