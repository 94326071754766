import React from 'react'

import './index.scss'

export default class WelcomeOverlay extends React.Component {

    public render() {
        const Zoom = require('react-reveal/Zoom')
        const Slide = require('react-reveal/Slide')

        return (
            <div className="welcome-overlay">
                <div className="welcome-overlay__section">
                    <Zoom>
                        <div className="welcome-overlay__header">
                            Ramell
                    </div>
                        <div className="welcome-overlay__header">
                            Collins
                    </div>
                    </Zoom>
                </div>
                <div className="welcome-overlay__section">
                    <Slide left>
                        <div className="welcome-overlay__sub-header">
                            Chef
                        </div>
                    </Slide>
                    <Slide right>
                        <div className="welcome-overlay__sub-header">
                            Software Engineer
                        </div>
                    </Slide>
                    <Slide left>
                        <div className="welcome-overlay__sub-header">
                            Food Science Enthusiast
                        </div>
                    </Slide>

                </div>
            </div>

        )
    }
}