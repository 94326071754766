import React from 'react';
import ViewSection from '../components/view_section';
import { IonTextarea, IonAlert } from '@ionic/react';
import ViewHeader from '../components/view_header';
import sendEmail from '../components/smtp_client/index';

import './tab_view.scss';

export interface ContactProps {
  toggleContactVisible: () => void;
}

export default class ContactView extends React.Component<ContactProps> {
  private contactText: string = '';
  private contactEmail: string = '';
  private messageSent: boolean = false;
  private showConfirmModal: boolean = false;
  private showNotifyModal: boolean = false;

  public render() {
    return (
      <>
        {!this.messageSent ? (
          <>
            <>
              <ViewHeader
                modalStyling={true}
                subheaders={['Send me a message']}
              >
                Want to get in touch with me?
              </ViewHeader>
              <ViewSection modalStyling={true}>
                <div className="contact-box">
                  <div className="contact-box__header">Email</div>
                  <div className="contact-box__header contact-box__header-input">
                    <div className="contact-box__email">
                      <IonTextarea
                        placeholder="email@domain.com"
                        value={this.contactEmail}
                        onIonChange={(e) =>
                          this.updateContactEmail(e.detail.value!)
                        }
                      />
                    </div>
                    <button
                      className="contact-box__send"
                      onClick={this.verifySendMessage}
                    >
                      Send Message
                    </button>
                  </div>
                  <div className="contact-box__header">Message</div>
                  <div className="contact-box__body">
                    <IonTextarea
                      placeholder="please enter a message"
                      value={this.contactText}
                      onIonChange={(e) =>
                        this.updateContactText(e.detail.value!)
                      }
                    />
                  </div>
                </div>
              </ViewSection>
            </>
            <IonAlert
              isOpen={this.showNotifyModal}
              backdropDismiss={true}
              mode="ios"
              onDidDismiss={() => {
                this.showNotifyModal = false;
                this.forceUpdate();
              }}
              cssClass="contact-box__confirm"
              header={'Insufficient information'}
              message={'Please Enter a valid email address and message.'}
              buttons={['Close']}
            />
            <IonAlert
              isOpen={this.showConfirmModal}
              backdropDismiss={true}
              mode="ios"
              onDidDismiss={() => {
                this.showConfirmModal = false;
                this.forceUpdate();
              }}
              cssClass="contact-box__confirm"
              header={'Confirm'}
              message={`email: ${this.contactEmail}, message: ${this.contactText}, confirm message?`}
              buttons={[
                {
                  text: 'Confirm',
                  role: 'confirm',
                  cssClass: 'secondary',
                  handler: () => {
                    this.send(this.contactText, this.contactEmail);
                  },
                },
                {
                  text: 'Cancel',
                  role: 'cancel',
                  cssClass: 'primary',
                  handler: () => {
                    this.showConfirmModal = false;
                    this.forceUpdate();
                  },
                },
              ]}
            />
          </>
        ) : (
          <div>Message Sent!</div>
        )}
      </>
    );
  }

  private updateContactText = (message: string) => {
    this.contactText = message;
  };

  private verifySendMessage = () => {
    const { contactEmail, contactText } = this;
    const emailValid =
      contactEmail && contactEmail.includes('.') && contactEmail.includes('@');
    if (contactText && emailValid) {
      this.showConfirmModal = true;
    } else {
      this.showNotifyModal = true;
    }
    this.forceUpdate();
  };

  private send = (message: string, userEmail: string) => {
    sendEmail({
      message_html: message,
      from_name: userEmail,
      reply_to: 'ramellcollins@gmail.com',
      subject: 'website inquiry',
    });
  };

  private updateContactEmail = (message: string) => {
    this.contactEmail = message;
  };
}
