import React from 'react';
import './index.scss';

export interface ViewSectionProps {
  center: boolean;
  tight: boolean;
  modalStyling?: boolean;
}

export default class ViewSection extends React.Component<ViewSectionProps> {
  public static defaultProps = {
    center: false,
    tight: false,
  };

  public render() {
    const { center, tight, modalStyling } = this.props;
    const bannerClass: string = modalStyling
      ? 'view-section view_section__modal'
      : tight && center
      ? 'view-section view-section__tight view-section__center'
      : center
      ? 'view-section__center'
      : 'view-section view-section__center';

    return <div className={bannerClass}>{this.props.children}</div>;
  }
}
