import React from 'react';
import {
  IonApp,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenu,
  IonMenuButton,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import ComputerResumeView from './views/comp_resume';
import WelcomeView from './components/welcome/slides';
import WelcomeOverlay from './components/welcome/overlay';
import FoodResumeView from './views/food_resume';
import ProjectView from './views/project_view';
import BioView from './views/bio';
import ContactView from './views/contact_view';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Theme variables */
import './theme/variables.css';

/* universal styling for site*/
import './theme/universal.scss';
import TabView from './views/tab_view';
import AboutMeView from './views/about-me';
import MobileView from './views/mobile';

export interface ToolBarOptions {
  dest: string;
  text: string;
}

const options: ToolBarOptions[] = [
  {
    dest: 'bio',
    text: 'Home',
  },
  {
    dest: 'about',
    text: 'About',
  },
  {
    dest: 'cResume',
    text: 'Software',
  },
  {
    dest: 'fResume',
    text: 'Culinary',
  },
  {
    dest: 'projects',
    text: 'Projects',
  },
];

const page = (
  tab: string,
  handleSelectDestination: (dest: string) => void,
  toggleContactVisible: () => void,
  viewMode: WindowMode
) => {
  if (viewMode === 'mobile' || viewMode === 'tablet-vertical') {
    return <MobileView toggleContactVisible={toggleContactVisible} />;
  }
  switch (tab) {
    case 'fResume':
      return (
        <TabView
          id="fResume"
          options={options}
          handleSelectDestination={handleSelectDestination}
          toggleContactVisible={toggleContactVisible}
          viewMode={viewMode}
        >
          <FoodResumeView viewMode={viewMode} />
        </TabView>
      );
    case 'projects':
      return (
        <TabView
          id="projects"
          options={options}
          handleSelectDestination={handleSelectDestination}
          toggleContactVisible={toggleContactVisible}
          viewMode={viewMode}
        >
          <ProjectView viewMode={viewMode} />
        </TabView>
      );
    case 'cResume':
      return (
        <TabView
          id="cResume"
          options={options}
          handleSelectDestination={handleSelectDestination}
          toggleContactVisible={toggleContactVisible}
          viewMode={viewMode}
        >
          <ComputerResumeView viewMode={viewMode} />
        </TabView>
      );
    case 'about':
      return (
        <TabView
          id="about"
          options={options}
          handleSelectDestination={handleSelectDestination}
          toggleContactVisible={toggleContactVisible}
          viewMode={viewMode}
        >
          <AboutMeView
            viewMode={viewMode}
            handleSelectDestination={handleSelectDestination}
          />
        </TabView>
      );
    case 'bio':
    default:
      return (
        <TabView
          id="bio"
          options={options}
          handleSelectDestination={handleSelectDestination}
          toggleContactVisible={toggleContactVisible}
          viewMode={viewMode}
        >
          <BioView
            viewMode={viewMode}
            handleSelectDestination={handleSelectDestination}
          />
        </TabView>
      );
  }
};

export type WindowMode = 'desktop' | 'mobile' | 'tablet' | 'tablet-vertical';

export class AppView extends React.Component {
  state = { width: 0, height: 0, showContactMe: false };
  private selectedView: string = 'bio';
  private mode: WindowMode = 'desktop';

  componentDidMount() {
    setTimeout(() => this.updateDimensions(), 50);
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  public render() {
    const visitedSite = localStorage.getItem('visitedSite') === 'T';
    const homeClass =
      visitedSite || this.mode === 'tablet-vertical' || this.mode === 'mobile'
        ? 'view-body__visited'
        : 'view-body';
    return (
      <IonApp>
        <div className="view">
          {!visitedSite &&
          this.mode !== 'tablet-vertical' &&
          this.mode !== 'mobile' ? (
            <div className="welcome">
              <WelcomeView />
              <WelcomeOverlay />
            </div>
          ) : null}
          <div>
            {this.mode === 'mobile' || this.mode === 'tablet-vertical' ? (
              <IonMenu side="start" menuId="first">
                <IonHeader>
                  <IonToolbar color="primary">
                    <IonButtons slot="start">
                      <IonMenuButton></IonMenuButton>
                    </IonButtons>
                    <IonTitle>Start Menu</IonTitle>
                  </IonToolbar>
                </IonHeader>
                <IonContent>
                  <IonList>
                    <IonItem>Menu Item</IonItem>
                    <IonItem>Menu Item</IonItem>
                    <IonItem>Menu Item</IonItem>
                    <IonItem>Menu Item</IonItem>
                    <IonItem>Menu Item</IonItem>
                  </IonList>
                </IonContent>
              </IonMenu>
            ) : (
              <></>
            )}
            <div className={homeClass} id="first">
              {page(
                this.selectedView,
                this.handleSelectDestination,
                this.toggleContactView,
                this.mode
              )}
              <IonModal
                isOpen={this.state.showContactMe}
                onDidDismiss={this.toggleContactView}
                cssClass={
                  this.mode === 'mobile' || this.mode === 'tablet-vertical'
                    ? 'mobile-contact'
                    : ''
                }
              >
                <ContactView toggleContactVisible={this.toggleContactView} />
              </IonModal>
            </div>
          </div>
        </div>
      </IonApp>
    );
  }

  private toggleContactView = () => {
    this.setState({ showContactMe: !this.state.showContactMe });
    this.forceUpdate();
  };

  public handleSelectDestination = (dest: string) => {
    this.selectedView = dest;
    this.forceUpdate();
    var wrapper = document.getElementById('wrapper');
    if (wrapper) {
      setTimeout(() => {
        wrapper!.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 250);
    }
  };

  private updateDimensions = () => {
    console.log('window: ', window.innerWidth, window.innerHeight);
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    const device = this.getDeviceType();

    if (device === 'tablet' && this.state.width <= 850) {
      this.mode = 'tablet-vertical';
    } else {
      this.mode = device;
    }
    this.forceUpdate();
  };

  private getDeviceType() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    } else if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile';
    } else if (window.innerWidth <= 1100) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }
}

const App: React.FC = () => <AppView />;

export default App;
