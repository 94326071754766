import React from 'react';
import ContactBar from '../components/contact-buttons/contact-bar-index';

export interface MobileViewProps {
  toggleContactVisible: () => void;
}

export default class MobileView extends React.Component<MobileViewProps> {
  public render() {
    return (
      <>
        <div className="tab-liner-mobile"></div>
        <div className="mobile-wrapper">
          <div className="mobile-text-header">Mobile View Coming Soon</div>
          <div className="mobile-contact">
            <ContactBar
              toggleContactVisible={this.props.toggleContactVisible}
            ></ContactBar>
          </div>
        </div>
      </>
    );
  }
}
