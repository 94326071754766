import React from 'react';
import Slides from '../horizontal-slides';
import CookingImg from '../../assets/welcome_photos/profile pic.jpg';
import Waterfall from '../../assets/welcome_photos/waterfall.jpg';
import Juelz from '../../assets/welcome_photos/juelz.jpg';
import Forest from '../../assets/welcome_photos/forest.jpg';

import './index.scss';

export default class WelcomeView extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      localStorage.setItem('visitedSite', 'T');
    }, 15000);
  }

  public render() {
    const slides = [
      {
        body: <img src={CookingImg} alt="" />,
      },
      {
        body: <img src={Waterfall} alt="" />,
      },
      {
        body: <img src={Juelz} alt="" />,
      },
      {
        body: <img src={Forest} alt="" />,
      },
    ];

    return (
      <div className="welcome-slides">
        <Slides slides={slides} />
      </div>
    );
  }
}
