import React from 'react';
import ViewSection from '../components/view_section';
import ResumeImage from '../components/resume-image';
import FZ from '../assets/resume_photos/fz-logo.png';
import GV from '../assets/resume_photos/gvsu-campus.jpg';
import Shane from '../assets/resume_photos/fz-shane.jpg';
import ResumeText from '../components/resume-text';
import { IonImg, IonItem, IonLabel, IonList } from '@ionic/react';
import { WindowMode } from '../App';

export interface CompResumeProps {
  viewMode: WindowMode;
}

export default class ComputerResumeView extends React.Component<CompResumeProps> {
  public render() {
    const Zoom = require('react-reveal/Zoom');
    const fzBio = [
      'I began at FZ as a Software Engineering Intern. I was in way over my head initially. The tech stack was extremely intimidating and consisted of many technologies. I was exposed to Microsoft Orleans, C# based API development, Entity Framework, Typescript based react, Hyper-V, Docker and Kubernetes. My primary focus was front end development and connectivity to the API',
      'When the Covid-19 pandemic happened I, along with many others from the company were let go. Fortunately I was offered a full time position after projects started to roll in. Since returning I have been tasked with owning more projects and building large scale projects for internal use. Working on a lean team, building software from the ground up has accelerated me far ahead of where I expected to be with 3 years of experience. Since returning I have been building internal applications using Typescript based angular, C# API development, SQL db management, python and perl scripting for data migration and server based functions as well as azure for Active Directory authentication and Dev ops for git and app deployment pipelines.',
      'I have been one of the primary designers and developers of the time keeping and reporting applications for the company. I have also had a hand in integrating applications into our eco-system and advising on the future of the application structure for the company. In just my few years here I have had a huge impact on the way that people work and operate on a daily basis and I feel proud of the work and learning I have done.',
    ];
    const gvBio = [
      "I began attending Grand Valley in 2013. I came out of culinary school and decided to immediately start my degree in computer science. I can tell you that was a mistake. Attending a full time class load while Working a full time job was hard, doing it as a young chef was nearly impossible. I would attend classes in the morning or at night as a cluster and then work 12-14 hours outside of that. I typically only had one day of rest a week and that was homework day. Initially I failed, a lot. After my first year I dropped out, because I couldn't handle it. I took a year off, re-evaluated my life and created a new plan to finish. I attended a lighter class-load year round, stayed dual enrolled at GRCC to save money Took a semester off when I needed. But I succeeded and as unfortunate as it is, I don’t have those club/sport/extra-curricular stories that every college kid is supposed to have. I don’t at all regret it, But I think it’s important when I talk about it that I experienced it like a working adult student and by the end of it, that’s exactly what I was.",
      'At the end of my college Career I was able to graduate with a major GPA of a 3.0. I never gave up and I am proud of myself for not only getting through, but developing myself into a talented young developer with experience in many different platforms and languages. It was only the beginning to a long life of continuous learning and since leaving I have done tons of self-investment to learn as much as possible But I would have never been able to do it without the foundations from my time at GVSU',
    ];

    if (
      this.props.viewMode === 'mobile' ||
      this.props.viewMode === 'tablet-vertical'
    ) {
      return <div className="view-wrapper">Mobile View Coming Soon</div>;
    } else {
      return (
        <div className="view-background">
          <ViewSection center={false}>
            <Zoom>
              <div className="tab-view__header">
                <IonImg
                  className="tab-view__image tab-view__image--passive"
                  src={Shane}
                />
                <div className="tab-view__title">Software</div>
              </div>
            </Zoom>
          </ViewSection>
          <ViewSection center={false}>
            <Zoom>
              <div className="tab-view-summary__wrapper">
                <ResumeText viewMode={this.props.viewMode} title="Summary">
                  I am an Aspiring IOT developer, with a focus on Full Stack
                  Development as well as Embedded development. I have 3 years of
                  experience building web apps for an electrical construction
                  company and am looking for Opportunities in the embedded
                  design and IOT fields. Over the years I have taken on many
                  personal projects in embedded design and have developed my own
                  IOT home server that operates using WiFi, Zigbee and Z-Wave
                  all Connected to a Custom Web App .
                </ResumeText>
                <div className="tab-view-summary__body">
                  <ResumeText viewMode={this.props.viewMode} title="Skills">
                    <IonList>
                      <IonItem>
                        <IonLabel>C/C#</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Java</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Linux Bash</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Python</IonLabel>
                      </IonItem>
                    </IonList>
                  </ResumeText>
                  <ResumeText viewMode={this.props.viewMode} title="">
                    <IonList>
                      <IonItem>
                        <IonLabel>Azure Dev Ops</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>AWS</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>CSS/SCSS</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Javascript/Typescript</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>React/Angular/Ionic</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Sql/ Sql Server Management Studio</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Arduino</IonLabel>
                      </IonItem>
                    </IonList>
                  </ResumeText>
                </div>
              </div>
            </Zoom>
          </ViewSection>
          <ViewSection>
            <ResumeImage src={FZ} viewMode={this.props.viewMode} />
            <ResumeText
              viewMode={this.props.viewMode}
              title="Feyen Zylstra"
              subheader="Software Engineer: April 2019 - Current"
            >
              <div>{fzBio[0]}</div>
              <div className="view-spacer">{fzBio[1]}</div>
              <div className="view-spacer">{fzBio[2]}</div>
            </ResumeText>
          </ViewSection>
          <ViewSection>
            <ResumeText
              viewMode={this.props.viewMode}
              title="Grand Valley State University"
              subheader="B.S in Computer Science, minor in computer engineering: 2020"
            >
              <div>{gvBio[0]}</div>
              <div className="view-spacer">{gvBio[1]}</div>
            </ResumeText>
            <ResumeImage src={GV} viewMode={this.props.viewMode} />
          </ViewSection>
          <ViewSection />
        </div>
      );
    }
  }
}
