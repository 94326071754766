import React from 'react'
import './index.scss'

export interface ViewHeaderProps {
    subheaders?: string[]
    homeStyling?: boolean
    modalStyling?: boolean
}

export default class ViewHeader extends React.Component<ViewHeaderProps> {

    
    public render() {

        const headerClass: string = this.props.modalStyling ? "view-header view-header__modal" : this.props.homeStyling ? "view-header view-header__home" : "view-header"

        const { subheaders } = this.props
        const sub = subheaders ? subheaders.map((s, idx) => {
            return (
                <div className="view-sub-header" key={idx}>
                    {s}
                </div>
            )
        }) : null

        return (
            <div className={headerClass}>
                {this.props.children}
                {sub}
            </div>
        )
    }

}