import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

export default class InstagramContact extends React.Component {
    public render() {
        return (
            <a className="link" href="https://www.instagram.com/___spyre___/">
            <FontAwesomeIcon icon={faInstagram} className="icon" size="2x" />
            </a>
        )
    }
}