import React from 'react';
import ViewSection from '../components/view_section';
import { IonImg } from '@ionic/react';
import { WindowMode } from '../App';

//picture imports
import microcontroller from '../assets/resume_photos/microcontroller.jpg';
import project from '../assets/resume_photos/project-banner.jpg';
import ResumeImage from '../components/resume-image';
import ResumeText from '../components/resume-text';
import ViewHeader from '../components/view_header';

export interface ProjectProps {
  viewMode: WindowMode;
}

export default class ProjectView extends React.Component<ProjectProps> {
  public render() {
    const embeddedBio =
      'In software engineering, embedded design and IOT are what I am most passionate about and will be the cornerstone of my career.\n Below I will be keeping a list of any running or completed projects in my repertoire';

    const Zoom = require('react-reveal/Zoom');

    if (
      this.props.viewMode === 'mobile' ||
      this.props.viewMode === 'tablet-vertical'
    ) {
      return <div className="view-wrapper">Mobile placeholder</div>;
    } else {
      return (
        <div className="view-background">
          <ViewSection center={false}>
            <Zoom>
              <div className="tab-view__header">
                <IonImg
                  className="tab-view__image tab-view__image--passive"
                  src={project}
                />
                <div className="tab-view__title">Projects</div>
              </div>
            </Zoom>
          </ViewSection>
          <Zoom>
            <ViewHeader>Project Page Coming SOON</ViewHeader>
          </Zoom>
          {/* <ViewSection>
            <ResumeText
              title="Embedded Design"
              subheader={embeddedBio}
              viewMode={this.props.viewMode}
            >
              <div>{'Project 1'}</div>
              <div>{'Project 1'}</div>
              <div>{'Project 1'}</div>
              <div>{'Project 1'}</div>
            </ResumeText>
            <ResumeImage src={microcontroller} viewMode={this.props.viewMode} />
          </ViewSection> */}
        </div>
      );
    }
  }
}
