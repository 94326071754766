import * as emailjs from 'emailjs-com';

export default function sendEmail(params: any) {
  emailjs.send(
    'gmail',
    'template_BvyVH9cr',
    params,
    'user_BIHoAsA7dj95AYJlxKhK1'
  );
}
