import React from 'react';
import ViewSection from '../components/view_section';
import { IonImg, IonItem, IonLabel, IonList } from '@ionic/react';
import ResumeImage from '../components/resume-image';
import ResumeText from '../components/resume-text';
import { WindowMode } from '../App';

import marcona from '../assets/resume_photos/marcona-logo.png';
import food from '../assets/resume_photos/food banner.jpg';
import bigDipper from '../assets/resume_photos/big-dipper.jpg';
import personal from '../assets/resume_photos/personal-chef.jpg';
import reserve from '../assets/resume_photos/reserve.jpg';
import secchia from '../assets/resume_photos/secchia.jpg';
import fishLads from '../assets/resume_photos/fish-lads.png';

export interface FoodResumeProps {
  viewMode: WindowMode;
}

export default class FoodResumeView extends React.Component<FoodResumeProps> {
  public render() {
    const Zoom = require('react-reveal/Zoom');

    const marconaBio = [
      'Marcona on Lyon was a high end, quality driven restaurant with a focus on Mediterranean cuisine. This was my last position in the culinary field and while here my duties involved working as a filler. It was one of the few places I worked where there wasn’t a single weak member of the kitchen. I am very proud to have worked with such talented chefs and feel pride knowing that I was trusted by those chefs to step in anywhere necessary without extra supervision and own what I was doing',
    ];
    const bigDipperBio = [
      'My Time working for Big Dipper was a huge step for me in my career. It was the first job I had where I was provided autonomy to do whatever was necessary to create new products. This included reaching out to vendors to source products, purchase R&D materials and create and document products. While it was a short lived position, it gave me a lot of confidence that I could be self-sustaining and provided great experience in providing a high quality, organized product and product roadmap',
    ];
    const privateBio = [
      'Over the course of a few years I tried my hat at working as a private chef, shortly before I graduated from culinary school I alongside many other promising students were asked to work for RDV as a special event staff. RDV is a company built to manage a home for private individuals. This job required me to not only cook extremely high end food, but also required me to provide service. This job demanded continuous professionalism that I carried along with me to future opportunities.',
      "After School as I began working in restaurants and getting settled into Grand Valley I came across many opportunities for small events in the homes of extended friends and family. At one time I was balancing 3 normal clients with monthly events. I really enjoyed working privately for people and I believe it has swayed the type of experience I provide in my own dinner project. Eventually I gave up private work as the amount of time it takes to prepare and execute wasn't sustainable while I was working full time and going to school, but it definitely was a great learning experience of what my career means outside of the rim of the plate.",
    ];
    const reserveBio = [
      'Reserve was the job that changed me from a rough around the edges line cook to a focused young and developing chef. Before working at Reserve I had a lead line position at fish lads. When I moved to Reserve I was instantly shown that I had a very long way to go before I would lead anything. I began from the bottom on garde manger and over time worked my way up to sautee where I was cultivated into the lead of the line.',
      "My time at Reserve showed me so much. I was able to participate in butchery, pasta making, dish creation, pastries and confections, ice cream, pickling and preserving, fermenting, etc. I regretfully left because I couldn't survive on the pay, due to the costs of school. However, once I left I began to realize the level of skill and knowledge imparted to me by the reserve team. I owe much of my career growth to the chefs I worked for and alongisde at Reserve. I hope one day to become someone they respect.",
    ];
    const fishBio = [
      'Fish Lads was the first job that taught me what it means to make real food. Before working at fish lads I spent time in a retirement community, a college cafe and a few high volume places getting my feet wet, but here I had to work at the same speed and prepare from scratch products. I was forced to learn ridiculous volume, multi-station line cooking, communication, large volume prep in short periods of time. It was an insane job and I think without it I would have never been able to take the step that led me to Reserve and for that I am very grateful',
    ];
    const secchiaBio = [
      "I didn't want to be a chef growing up, I attended culinary school because I needed a skill to support myself and figure out how I was going to get my actual degree. At Secchia I was one of the youngest graduates to come out of the program at that point. I graduated at 19, with no idea what I was going to do. Most of the students around me were already well established with nearly as much experience as I have today. I was always chasing the tail of a partner who was better than me, I think that paved the beginning of my career. I always was a little too confident and reached a little too far and when I did I had to prove that I was worth my salt. This mindset changed who I was, forced me to work harder, prove my capabilities. I was fortunate enough to learn from two master chefs, meet the U.S. pastry team and begin my discovery into food science. Secchia made me a chef in an unreal way and has been more important to my development as a young adult and professional than anything else I have ever done.",
    ];

    if (
      this.props.viewMode === 'mobile' ||
      this.props.viewMode === 'tablet-vertical'
    ) {
      return (
        <div className="view-wrapper">
          <div className="tab-view__title">Culinary</div>
        </div>
      );
    } else {
      return (
        <div className="view-background">
          <ViewSection center={false}>
            <Zoom>
              <div className="tab-view__header">
                <IonImg
                  className="tab-view__image tab-view__image--passive"
                  src={food}
                />
                <div className="tab-view__title">Culinary</div>
              </div>
            </Zoom>
          </ViewSection>
          <ViewSection center={false}>
            <Zoom>
              <div className="tab-view-summary__wrapper">
                <ResumeText viewMode={this.props.viewMode} title="Summary">
                  I am an Experienced Chef that has moved into the world of
                  Software Engineering. I have Continued to Work Independently
                  in the Food Industry. I now focus on Personal Projects and am
                  Growing a Company in the Restaurant World as a Showcase for my
                  Food and Technology Products. If you would like to see what I
                  can do. Contact me to get a seat at a Spyre Dinner
                </ResumeText>
                <div className="tab-view-summary__body">
                  <ResumeText viewMode={this.props.viewMode} title="Skills">
                    <IonList>
                      <IonItem>
                        <IonLabel>Menu Conceptualization/Design</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Full Service Private Chef</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Management</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Farm to table focused service</IonLabel>
                      </IonItem>
                    </IonList>
                  </ResumeText>
                  <ResumeText viewMode={this.props.viewMode} title="">
                    <IonList>
                      <IonItem>
                        <IonLabel>Preservation/Fermentation</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Gastronomy/Modern Techniques</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>ServSafe Alcohol</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>ServSafe Manager</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Purchasing</IonLabel>
                      </IonItem>
                    </IonList>
                  </ResumeText>
                </div>
              </div>
            </Zoom>
          </ViewSection>
          <ViewSection>
            <ResumeImage src={marcona} viewMode={this.props.viewMode} />
            <ResumeText
              viewMode={this.props.viewMode}
              title="Marcona on Lyon"
              subheader="Line Cook: September 2018 - February 2019"
            >
              {marconaBio}
            </ResumeText>
          </ViewSection>
          <ViewSection>
            <ResumeText
              viewMode={this.props.viewMode}
              title="Big Dipper Dough"
              subheader="Research and Development Chef: July 2018 - february 2019"
            >
              {bigDipperBio}
            </ResumeText>
            <ResumeImage src={bigDipper} viewMode={this.props.viewMode} />
          </ViewSection>
          <ViewSection>
            <ResumeImage src={personal} viewMode={this.props.viewMode} />
            <ResumeText
              viewMode={this.props.viewMode}
              title="Private Chef"
              subheader="Personal Chef: 2014 - 2017"
            >
              <div>{privateBio[0]}</div>
              <div className="view-spacer">{privateBio[1]}</div>
            </ResumeText>
          </ViewSection>
          <ViewSection />
          <ViewSection>
            <ResumeText
              viewMode={this.props.viewMode}
              title="Reserve Wine and Food"
              subheader="Lead Line Cook: May 2015 - July 2017"
            >
              <div>{reserveBio[0]}</div>
              <div className="view-spacer">{reserveBio[1]}</div>
            </ResumeText>
            <ResumeImage src={reserve} viewMode={this.props.viewMode} />
          </ViewSection>
          <ViewSection>
            <ResumeImage src={fishLads} viewMode={this.props.viewMode} />
            <ResumeText
              viewMode={this.props.viewMode}
              title="Fish Lads"
              subheader="Saute Line Cook: May 2014 - May 2015"
            >
              {fishBio}
            </ResumeText>
          </ViewSection>
          <ViewSection>
            <ResumeText
              viewMode={this.props.viewMode}
              title="Secchia Institute of culinary arts"
              subheader="A.A in Culinary Arts 2013"
            >
              {secchiaBio}
            </ResumeText>
            <ResumeImage viewMode={this.props.viewMode} src={secchia} />
          </ViewSection>
          <ViewSection />
        </div>
      );
    }
  }
}
